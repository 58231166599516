import { render, staticRenderFns } from "./aboutUs.vue?vue&type=template&id=60b5599f&scoped=true&"
import script from "./aboutUs.vue?vue&type=script&lang=js&"
export * from "./aboutUs.vue?vue&type=script&lang=js&"
import style0 from "./aboutUs.vue?vue&type=style&index=0&id=60b5599f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b5599f",
  null
  
)

export default component.exports