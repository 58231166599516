<template>
  <div id='aboutUs'>
    <div class='banner_1'>
      <div class='swiper_content'>
        <!--        <div class="swiper-container">-->
        <!--        <swiper :options='swiperOptions1'>-->
        <!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url && logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null'
             target='_blank'><img
            :src='logoList[0].img' /></a>
        </div>
        <!--          </swiper-slide>-->
        <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
        <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
        <!-- 如果需要导航按钮 -->
        <!--          <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
        <!--          <div class='swiper-button-next next1' slot='button-next'></div>-->
        <!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
    <!--    <img src='../assets/about/hxxdBg.png' alt='' class='banner-img'>-->
    <div class='park_wrapper'>
      <div class='park_wrapper_inner'>
        <div class='part_title'>{{ $t('about.hxTitle') }}</div>
        <div class='park_content'>
          <div class='park_content_left'>
            <div class='park_company'>
              {{ $t('about.hxTitleSup') }}
            </div>
            <div class='park_company_sub'>
              <pre>{{ $t('about.hxTitleEn') }}</pre>
            </div>
            <el-divider></el-divider>
            <div class='park_company_text'>
              {{ $t('about.hxYQ') }}
            </div>
            <div class='park_company_sub' style='color: #666'>
              <pre>Brief Introduction</pre>
            </div>
            <div class='park_company_sub_text'>
<!--              <p>{{ $t('about.hxYQContent') }}</p>-->
<!--              <p>{{ $t('about.hxYQContentE') }}</p>-->
<!--              <p>{{ $t('about.hxYQContentT') }}</p>-->
              <p>{{dataJson.hx_desc}}</p>
            </div>
          </div>

          <div class='park_content_right'>
            <div class='content_right_imge'>
              <img :src='dataJson.park_desc_img' alt=''>
            </div>
            <el-divider></el-divider>
            <div class='content_right_detail'>
              <div class='right_detail_item'>
                <span class='dot'>{{ $t('about.lOne') }}</span>
                <div class='dot_detail'>{{ $t('about.lOneName') }}</div>
              </div>
              <div class='right_detail_item detail_item'>
                <span class='dot'>{{ $t('about.lTwo') }}</span>
                <div class='dot_detail'>{{ $t('about.lTwoName') }}</div>
              </div>
              <div class='right_detail_item'>
                <span class='dot'>{{ $t('about.lThree') }}</span>
                <div class='dot_detail'>{{ $t('about.lThreeName') }}</div>
              </div>
              <div class='right_detail_item detail_item'>
                <span class='dot'>{{ $t('about.lFour') }}</span>
                <div class='dot_detail'>{{ $t('about.lFourName') }}</div>
              </div>
              <div class='right_detail_item'>
                <span class='dot'>{{ $t('about.lFive') }}</span>
                <div class='dot_detail'>{{ $t('about.lFiveName') }}</div>
              </div>
              <div class='right_detail_item detail_item'>
                <span class='dot'>{{ $t('about.lSix') }}</span>
                <div class='dot_detail'>{{ $t('about.lSixName') }}</div>
              </div>
              <div class='right_detail_item '>
                <span class='dot'>{{ $t('about.lSeven') }}</span>
                <div class='dot_detail'>{{ $t('about.lSevenName') }}</div>
              </div>
              <div class='right_detail_item detail_item'>
                <span class='dot'>{{ $t('about.lEight') }}</span>
                <div class='dot_detail'>{{ $t('about.lEightName') }}</div>
              </div>
              <div class='right_detail_item'>
                <span class='dot'>{{ $t('about.lNine') }}</span>
                <div class='dot_detail'>{{ $t('about.lNineName') }}</div>
              </div>
              <!--              <div class="right_detail_item detail_item">-->
              <!--                <span class="dot">10栋</span>-->
              <!--                <div class="dot_detail">内容中心C座</div>-->
              <!--              </div>-->
              <!--              <div class="right_detail_item detail_item">-->
              <!--                <span class="dot">3栋</span>-->
              <!--                <div class="dot_detail"></div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="park_dot">-->
      <!--        <img src="../assets/about/dot.png" />-->
      <!--      </div>-->
    </div>
    <div class='head-info-item'>
      <div class='rectangle-position'>
        <img src='../assets/rectangle.png' alt=''>
      </div>
      <div class='header-anchor' style='background-color: #f5f5f5;'>
        <!--        <div class='rectangle-right'>-->
        <!--          <img src='../assets/rectangle.png' alt=''>-->
        <!--        </div>-->
        <div class='box_introduction_title'>
          <div class='introduction_title'>{{ $t('about.hxFZ') }}</div>
          <!--          <img src='../assets/about/development.png'/>-->
        </div>
        <div class='live_swiper'>
          <!--        <div class="swiper-container">-->
          <swiper class='swiperLive' :options='optionS' v-if='list.length' ref='swiperLive' id='swiper-container'>
            <swiper-slide v-for='(item,index) in list' :key='index'>
              <div class='wrapper_item'>
                <div class='wrapper_item_left'>
                  <div class='wrapper_item_left_year'>
                    <img :src='item.year_img' />
                  </div>
                  <div class='wrapper_item_brief'>
                    {{ item.desc }}
                  </div>
                </div>
                <div class='wrapper_item_right'>
                  <img :src='item.img' />
                </div>
              </div>
              <!--              <img :src='item' />-->
            </swiper-slide>
            <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
            <div class='swiper-pagination swiper-pagination1' slot='pagination'>

            </div>
            <!-- 如果需要导航按钮 -->
            <!--            <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
            <!--            <div class='swiper-button-next next1' slot='button-next'></div>-->
          </swiper>
          <el-divider></el-divider>
          <!--      </div>-->
        </div>
      </div>

    </div>
    <div class='new_about'>
      <!--      <div class='rectangle-right'>-->
      <!--        <img src='../assets/rectangle.png' alt=''>-->
      <!--      </div>-->
      <div class='box_introduction_title'>
        <div class='introduction_title'>{{ $t('about.hxRY') }}</div>
        <!--        <img src='../assets/about/honor.png' class="hon" />-->
      </div>
      <div class='swiper_content'>
        <div class='game_swiper'>
          <!--        <div class="swiper-container">-->
          <swiper :options='swiperOptions3' :auto-update='true' ref='mySwiper'>
            <swiper-slide v-for='(item,index) in honorList' :key='index'>
              <div class='swiper_item_wrapper'>
                <div class='swiper_item_img'>
                  <img :src='item.img' />
                </div>
                <div class='swiper_item_text'>{{ item.title }}</div>
              </div>
            </swiper-slide>
            <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
            <!--            <div class="swiper-pagination" slot="pagination"></div>-->
            <!-- 如果需要导航按钮 -->
          </swiper>
          <div class='swiper-button-prev prev3' slot='button-prev' @click='prevChange'></div>
          <div class='swiper-button-next next3' slot='button-next' @click='nextChange'></div>
          <!--      </div>-->
        </div>
      </div>
      <!--      <div class='Semicircle_right'>-->
      <!--        <img src='../assets/about/Semicircle.png' />-->
      <!--      </div>-->

    </div>
    <div class='new' ref='newContent'>
      <div class='box_introduction_title'>
        <div class='introduction_title'>{{ $t('about.hxNews') }}</div>
        <!--        <img src='../assets/about/Journalism.png' />-->
      </div>
      <!--      <div class='Semicircle_right'>-->
      <!--        <img src='../assets/home/Semicircle.png' />-->
      <!--      </div>-->
      <div class='new_wrapper'>
        <div class='new_content'>
          <div class='item_new' v-for='(item,index) in newList' :key='index'>
            <a :href='item.url' target='_blank'>
              <div class='imge_wrapper'>
                <img :src='item.img' />
              </div>
              <div class='item_new_content'>
                <div class='item_new_title'>{{ item.title }}</div>
                <div class='item_new_text' v-html='item.desc'></div>
              </div>
              <div class='new_time'>{{ item.created_at }}</div>
            </a>
          </div>
        </div>
        <div class='know_us'>
          <el-button class='know_more' @click='addCurrentPage' v-if='!noData'>
            <span>{{ $t('about.hxMore') }} >>></span>
          </el-button>
          <el-button class='know_more_nodata' v-else disabled>
            <span>{{ $t('about.hxNoMore') }} >>></span>
          </el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
// import homeBanner from '../components/homeBanner'
import '../../node_modules/echarts/map/js/china.js'

import { Logo, index_List, history_honor_List, honor_List, Home_News_list } from '@/api/banner'

export default {
  name: 'aboutUs',
  components: {
    // homeBanner
  },
  // watch:{
  //   $route: {
  //     handler: function (to,from) {
  //       console.log("跳转回参数页面", to,from);
  //       // this.redirect = route.query && route.query.redirect;
  //     },
  //     immediate: true,
  //     deep:true
  //   },
  //
  // },
  data() {
    return {
      logoList: [],
      swiperOptions1: {
        // 自动播放
        autoplay: {
          delay: 3000
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1'
        }
      },
      honorList: [],
      list: [],
      total: null,
      currentPage: 1,
      newList: [],
      dataJson: {},
      optionS: {
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        // 自动播放
        loop: true,
        initialSlide: 0,
        loopedSlides: 2,
        loopAdditionalSlides: 0,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 环路播放
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        pagination: {
          el: '.swiper-pagination.swiper-pagination1',
          clickable: true
        },
        on: {
          slideChangeTransitionStart: function() {
            // console.log(this.activeIndex);
          }
        }

      },
      swiperOptions2: {},
      swiperOptions3: {
        // 自动播放
        autoplay: true,
        // 环路播放
        loop: false,
        // 切换效果
        effect: 'slide',
        slidesPerView: 6,   //页面分组显示，这里显示为3组
        spaceBetween: 30,
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next3',
          prevEl: '.swiper-button-prev.prev3'
        }
      },
      noData: false
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiperLive.swiper
    }
  },
  mounted() {
    if (this.$route.query.url) {
      this.$el.querySelector('.new').scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    }
    this.get_Logo()
    this.get_index_List()
    this.get_history_honor_List()
    this.get_honor_List()
    this.get_Home_News_list()
    let language = localStorage.getItem('locale')
  },
  methods: {
    newDetail: function(id) {
      this.$router.push({
        path: '/newsDetails',
        query: { id: id }
      })
    },
    get_Logo: function() {
      Logo(7).then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'logoList', resp.data)
        }
      }).catch(error => {

      })
    },
    formatDate(item_date) {
      let date = new Date(parseInt(item_date) * 1000)
      let YY = date.getFullYear() + '-'
      let MM =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return YY + MM + DD
    },
    get_index_List: function() {
      index_List().then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'dataJson', resp.data)
        }
      }).catch(error => {

      })
    },
    get_history_honor_List: function() {
      history_honor_List().then(resp => {
        if (resp.status == 200) {
          let list = resp.data

          let option = {
            observer: true,//修改swiper自己或子元素时，自动初始化swiper
            observeParents: true,//修改swiper的父元素时，自动初始化swiper
            // 自动播放
            loop: true,
            initialSlide: 0,
            loopedSlides: 2,
            loopAdditionalSlides: 0,
            speed: 1000,
            autoplay: {
              delay: 5000,
              stopOnLastSlide: false,
              disableOnInteraction: false
            },
            // 环路播放
            // 切换效果
            effect: 'slide',
            cubeEffect: {
              slideShadows: true,
              shadow: true,
              shadowOffset: 100,
              shadowScale: 0.6
            },
            slideToClickedSlide: true, //点击滑到相应的silde
            // 前进后退按钮
            pagination: {
              el: '.swiper-pagination.swiper-pagination1',
              clickable: true,
              renderBullet: function(index, className) {
                let text = ''
                switch (index) {
                  case 0:
                    text = list[index].year
                    break
                  case 1:
                    text = list[index].year
                    break
                  case 2:
                    text = list[index].year
                    break
                  case 3:
                    text = list[index].year
                    break
                  case 4:
                    text = list[index].year
                    break
                  case 5:
                    text = list[index].year
                    break
                  case 6:
                    text = list[index].year
                    break
                  case 7:
                    text = list[index].year
                    break
                  case 8:
                    text = list[index].year
                    break
                  case 9:
                    text = list[index].year
                    break
                  case 10:
                    text = list[index].year
                    break
                  case 11:
                    text = list[index].year
                    break
                  case 12:
                    text = list[index].year
                    break
                  case 13:
                    text = list[index].year
                    break
                  case 14:
                    text = list[index].year
                    break
                  case 15:
                    text = list[index].year
                    break
                  case 16:
                    text = list[index].year
                    break
                  case 17:
                    text = list[index].year
                    break
                  case 18:
                    text = list[index].year
                    break
                }
                return '<span class="' + className + '" style="margin-right: 120px">' + text + '</span>'
              }
            },
            on: {
              slideChangeTransitionStart: function() {
                // console.log(this.activeIndex);
              }
            }

          }
          this.$set(this, 'list', resp.data)
          this.$set(this, 'optionS', option)
          // this.$nextTick(()=>{
          //   var mySwiper = new Swiper('#swiper-container',option);
          //   // setInterval(function() {
          //   //   mySwiper.slideNext();
          //   // }, 1000)
          // })

        } else {
          this.$message({
            type: 'info',
            message: resp.msg
          })
        }
      }).catch(error => {
      })
    },
    get_Home_News_list: function() {
      let data = {
        page: this.currentPage,
        limit: 3
      }
      Home_News_list(data).then(resp => {
        if (resp.status == 200) {
          if (this.newList.length < resp.data.meta.pagination.total) {
            resp.data.data.forEach(item => {
              // item.created_at = this.formatDate(item.created_at)
              item.created_at = item.created_at.substring(0,10);
              console.log(item.created_at)
            })
            this.newList = [...this.newList, ...resp.data.data]
            this.$set(this, 'currentPage', ++this.currentPage)
          } else {
            this.noData = true
          }
          this.$set(this, 'total', resp.data.meta.pagination.total)

        }
      }).catch(error => {

      })
    },
    addCurrentPage: function() {
      this.get_Home_News_list()
    },
    // get_list_page:function(index,className,data){
    //   console.log('---------999',index,data)
    //   let text=''
    //   switch(index){
    //     case 0:text='2015';break;
    //     case 1:text='2017';break;
    //     case 2:text='2018';break;
    //     case 3:text='2019';break;
    //     case 4:text='2020';break;
    //   }
    //   return '<span class="' + className + '" style="margin-right: 120px">' + text + '</span>';
    // },
    get_honor_List: function() {
      honor_List().then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'honorList', resp.data)

        } else {
          this.$message({
            type: 'info',
            message: resp.msg
          })
        }
      }).catch(error => {

      })
    },
    renderBullets: function(index, className) {
      let text = ''
      switch (index) {
        case 0:
          text = '2015'
          break
        case 1:
          text = '2017'
          break
        case 2:
          text = '2018'
          break
        case 3:
          text = '2019'
          break
        case 4:
          text = '2020'
          break
      }
      return '<span class="' + className + '" style="margin-right: 120px">' + text + '</span>'
    },
    prevChange() {
      this.$refs.mySwiper.$swiper.slidePrev()
    },
    nextChange() {
      this.$refs.mySwiper.$swiper.slideNext()
    }
  }
}
</script>

<style scoped lang='scss'>
.banner_1 {
  width: 100%;
  height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

    .swiper-container {
      width: 100%;
      height: 700px;

      .swiper-wrapper {
        width: 100%;
        height: 700px;

        .swiper-slide {
          width: 100%;
          height: 700px;

          img {
            width: 100%;
            height: 700px;
          }
        }
      }

    }

    img {
      width: 100%;
      height: 700px;
    }
  }
}

.park_wrapper {
  width: 100%;
  padding: 67px 0px 0 0;
  position: relative;

  .park_wrapper_inner {
    box-sizing: border-box;
    width: 1364px;
    /*height: 805px;*/
    margin-left: calc(50% - 682px);
    //background-color: rgba(213, 71, 36, 1);
    color: #333;
    padding: 40px 75px 0 100px;

    .part_title {
      text-align: center;
      font-size: 26px;
      font-family: SourceHanSansCNVF;
      font-weight: 400;
      color: #333;
      line-height: 50px;
    }

    .park_content {
      margin-top: 15px;
      display: flex;
      flex-flow: row nowrap;

      .park_content_left {
        width: 660px;

        .park_company {
          font-size: 50px;
          color: rgba(213, 71, 36, 1);
          font-weight: 550;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          span {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #fff;
            display: inline-block;
            margin-left: 16px;
            margin-right: 16px;
          }
        }

        .park_company_sub {
          margin-top: 8px;
          font-size: 14px;
          font-weight: 600;
          color: #333;
        }

        .park_company_text {
          font-size: 28px;
          color: rgba(213, 71, 36, 1);
          font-weight: 550;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }

        .park_company_sub_text {
          margin-top: 20px;
          width: 530px;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #4D4D4D;
          line-height: 28px;
          text-align: justify;
          p{
            text-indent: 32px;
          }
        }
      }

      .park_content_right {
        width: 590px;

        .content_right_imge {
          width: 590px;
          height: 468px;
          margin-bottom: 20px;

          img {
            width: 590px;
            height: 468px;
          }
        }

        .content_right_detail {
          display: flex;
          flex-flow: row wrap;
          padding: 0 0 0 60px;

          .right_detail_item {
            width: 240px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 5px;

            .dot {
              width: 28px;
              height: 28px;
              display: flex;
              padding: 15px;
              justify-content: center;
              align-items: center;
              background-color: rgba(213, 71, 36, 1);
              border-radius: 50%;
              color: #343434;
              text-align: center;
              font-size: 14px;
              margin-right: 10px;
              letter-spacing: -1.5px;
              transform: scale(0.8);
            }

            .dot_detail {
              flex: 1;
              font-size: 12px;
              color: #333;
              line-height: 26px;
            }
          }

          .detail_item {
            width: 280PX;
          }
        }
      }
    }
  }

  .park_dot {
    width: 126px;
    height: 95px;
    position: absolute;
    top: 65px;
    right: 120px;

    img {
      width: 126px;
      height: 95px;
    }
  }
}

.new {
  width: 100%;
  min-height: 674px;
  background-color: #F6F6F6;
  margin: 67px 0px;
  position: relative;
  padding-top: 10px;

  .Semicircle_right {
    position: absolute;
    //z-index: -1;
    right: 0;
    top: -70px;
    width: 326px;
    height: 726px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.new_about {
  width: 100%;
  height: 340px;
  position: relative;

  .rectangle-right {
    position: absolute;
    right: 100px;
  }

  .swiper_content {
    width: 1200px;
    height: 210px;
    margin-left: calc(50% - 600px);
    margin-top: 67px;

    .game_swiper {
      width: 1200px;
      height: 220px;

      .swiper-container {
        width: 1200px;
        height: 220px;
        padding-left: 10px;

        .swiper-wrapper {
          width: 1200px;
          height: 220px;

          .swiper-slide {
            width: 160px;
            height: 220px;

            .swiper_item_wrapper {
              width: 154px;
              height: 220px;
              padding-top: 10px;

              .swiper_item_img {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                //border: 1px solid #D54724;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  box-shadow: 0px 0px 14px 0px rgba(46, 46, 46, 0.2000);
                }
              }

              .swiper_item_text {
                margin-top: 15px;
                font-size: 14px;
                font-family: SourceHanSansCNVF;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                text-align: center;
              }
            }
          }
        }

      }

      .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 280px;
        top: 160px;
        width: 46px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        border-radius: 50%;
        color: #D54724;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(21, 25, 37, 0.1700);
      }

      .swiper-button-next, .swiper-container-rtl .swiper-button-next {
        right: 280px;
        top: 160px;
        width: 46px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        border-radius: 50%;
        color: #D54724;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(21, 25, 37, 0.1700);
      }

      .swiper-button-next:hover, .swiper-button-prev:hover {
        background-color: #DB6143;
        color: #fff;
      }
    }
  }

  .Semicircle_right {
    position: absolute;
    width: 375px;
    height: 730px;
    bottom: 35px;
  }
}

.new_wrapper {
  width: 100%;
  min-height: 674px;
  background-color: transparent;
  z-index: 1;
  box-sizing: border-box;
  padding: 67px 316px 20px 400px;

  .new_content {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: start;

    .item_new {
      width: 340px;
      height: 450px;
      background-color: #fff;
      border-top-left-radius: 30px;
      position: relative;
      //margin-left: 20px;
      //margin-right: 48px;
      margin-right: 60px;
      margin-bottom: 40px;
      align-items: flex-start;
      cursor: pointer;
      border-radius: 30px 0px 30px 0px;

      a {
        text-decoration: none;
      }

      .imge_wrapper {
        box-sizing: border-box;
        width: 100%;
        height: 280px;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        //border: 1px solid #D54724;
        img {
          width: 100%;
          height: 280px;
          border-top-left-radius: 30px;
          border-bottom-right-radius: 30px;
          //border: 1px solid #D54724;
        }
      }

      .item_new_content {
        padding: 0px 22px 10px 15px;

        .item_new_title {
          font-size: 20px;
          font-family: SourceHanSansSC;
          font-weight: 500;
          color: #4D4D4D;
          line-height: 50px;
        }

        .item_new_text {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #999999;
          line-height: 20px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          text-align: justify;
        }


      }

      .new_time {
        position: absolute;
        left: 15px;
        bottom: 10px;
        color: #999;
        margin-top: 10px;
        font-size: 12px;
      }

    }

    .item_new:hover {
      box-shadow: 0px 0px 8px 0px rgba(21, 25, 37, 0.3500);
    }
  }
}

.box_introduction_title {
  margin-top: 50px;
  width: 100%;
  position: relative;

  .introduction_title {
    width: 240px;
    height: 25px;
    font-size: 26px;
    font-family: SourceHanSansCNVF;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-left: calc(50% - 120px);
    text-align: center;
  }

  img {
    position: absolute;
    width: 390px;
    height: 45px;
    margin-left: calc(50% - 5px);
    top: 5px;
  }
}

.el-divider--horizontal {
  width: 540px;
  background-color: rgba(215, 215, 215, 0.1);
  height: 2px;
  margin-left: 25px;
  margin-top: 10px;
}

.know_us {
  text-align: center;

  .know_more {
    background-color: #D54724;
    color: #fff;
    cursor: pointer;
  }

  .know_more_nodata {
    background-color: rgba(213, 71, 36, 0.5);
    color: #fff;
  }
}

.el-button {
  border: none;
  outline: none;
  border-radius: 50px;
}

.park_content_left {
  .el-divider--horizontal {
    width: 490px !important;
    background-color: rgba(215, 215, 215, 0.1);
    height: 2px;
    margin-top: 15px;
    margin-left: 0px;
    margin-bottom: 8px;
  }
}

.head-info-item {
  //display: flex;
  position: relative;
  padding: 30px 0;

  .header-anchor {
    width: 100%;
    margin: auto;
    font-size: 20px;
    font-family: SourceHanSansSC;
    font-weight: 550;
    color: #333;
    position: relative;
    line-height: 30px;
    padding-top: 10px;

    .images {
      width: 321px;
      position: absolute;
      left: 50%;
    }

    .popular-img {
      width: 191px;
    }

    .explosion-img {
      width: 221px;
    }

    .information-img {
      width: 261px;
    }

    .address-word {
      width: 181px;
    }

    .shop-content-info {
      background: rgba(213, 71, 36, 0.1);
      box-shadow: 0px 8px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 40px;
      height: 428px;
      width: 88%;
      overflow: hidden;
      margin: auto;
    }

    .hx-yanxuan {
      height: 446px;
      width: 88%;
      overflow: hidden;
      margin: auto;
    }
  }

  .rectangle-position {
    position: absolute;
    bottom: 30px;
    left: 60px;

    img {
      width: 104px;
      height: 73px;
    }
  }

  .lefts {
    left: 140px;
    bottom: 240px;
  }

  .rectangle-right {
    position: absolute;
    top: 80px;
    left: 100px;

    img {
      width: 106px;
      height: 73px;
    }
  }

  .left-s {
    position: absolute;
    left: 0;
    top: -200px;
    opacity: 0.3;

    img {
      width: 355px;
      height: 657px;
    }
  }

  .rights {
    right: 160px;
  }

  .swiper-slide {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }

  .anchor-list {
    padding: 30px 0 0 70px;
  }

  .anchor-list div:nth-child(1) {
    font-size: 20px;
    color: #333333;
    line-height: 50px;
  }

  .anchor-list div:nth-child(3) {
    margin: 50px 0 150px 0;
  }

  .anchor-list span:nth-child(4) {
    background: #D54724;
    border-radius: 23px;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
  }

  .anchor-avatar {
    width: 428px;
    height: 100%;
    border-radius: 40px;
  }

  .yanxuan-list {
    background: #D54724;
    border-radius: 40px 40px 40px 40px;
    height: 446px;
  }

  .lists-con {
    display: flex;
    align-items: center;
    width: 50%;
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
  }

  .yanxuan-avatar {
    width: 217px;
    height: 100%;
    border-radius: 40px 0 0 40px;
  }

  .imgs {
    border-radius: 0 0 0 0;
  }

  .yanxuan-name {
    font-size: 18px;
    line-height: 47px;
    text-align: center;
  }

  .yx-number {
    padding-left: 76px;
    line-height: 25px;
  }

  .yx-number > div:nth-child(2) {
    margin-bottom: 10px;
  }

  .yx-number > span:nth-child(3) {
    background: #FFFFFF;
    color: #D54724;
    padding: 5px 5px;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: bold;
  }

  .hot-case {
    width: 85%;
    margin: auto;
    height: 527px;
    overflow: hidden;
  }

  .avatar2 {
    width: 100%;
    height: 100%;
    background: rgba(213, 71, 36, 0.1);
    box-shadow: 0px 3px 3px 0px rgba(64, 64, 64, 0.1);
    border-radius: 40px;
  }

  .hot-information {
    width: 85%;
    margin: auto;
    height: 233px;
    overflow: hidden;
  }

  .cooperation-brand {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      width: 102px;
      height: 102px;
      box-shadow: 6px 6px 5px 0 rgba(243, 228, 225, 1);
      border-radius: 20px;
      margin-top: 20px;
      margin-right: 30px;
    }
  }
}

.swiper1 {
  width: 100%;
  height: 428px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
}

.live_swiper {
  width: 100%;
  height: 840px;
  position: relative;

  .swiper-container {
    width: 100%;
    height: 840px;

    .swiper-wrapper {
      width: 100%;
      height: 840px;

      .swiper-slide {
        width: 100%;
        height: 840px;

        .wrapper_item {
          width: 100%;
          padding-top: 100px;
          padding-left: 300px;
          padding-right: 300px;
          box-sizing: border-box;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;

          .wrapper_item_left {
            .wrapper_item_left_year {
              width: 430px;
              height: 146px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .wrapper_item_brief {
              margin-top: 40px;
              width: 430px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #4D4D4D;
              line-height: 28px;
              text-align: justify;
            }
          }

          .wrapper_item_right {
            width: 650px;
            height: 440px;
            border-radius: 40px;
            //border: 1px solid #D54724;
            img {
              width: 100%;
              height: 100%;
              border-radius: 40px;
            }
          }
        }

        /*img {*/
        /*  width:100%;*/
        /*  height: 750px;*/
        /*}*/
      }
    }

  }

  .el-divider--horizontal {
    position: absolute;
    width: 100% !important;
    z-index: 20;
    /*background-color: #D54724;*/
    margin-left: 0px;
    height: 5px;
    border-top: 1px dashed rgba(232, 75, 36, 0.5);
    bottom: 35px;
  }
}

.hon {
  width: 188px !important;
  height: 45px;
}

.know_us {
  text-align: center;

  .know_more {
    background-color: #D54724;
    color: #fff;
    cursor: pointer;
  }
}

.el-button {
  border: none;
  outline: none;
  border-radius: 50px;
}

.el-button:hover {
  background-color: rgba(213, 71, 36, 0.3) !important;
  color: #fff !important;
}
</style>
